import { makeStyles } from '@material-ui/core/styles';
import { Directions } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: '-42px',
        left: '9.5vw',
        display: 'block',
        zIndex: 1200,
        display : 'flex',
        flexDirection : 'column',
        backgroundColor: theme.palette.primary.main,
        padding : theme.spacing(1.5),
        borderRadius: '1em',
        cursor : 'pointer',
        transition : 'bottom ease 300ms',
        '&:hover': {
            bottom : '4px',
        },
        '& span' : { 
            color : '#fff',
        }
    },
    rootHidden: {
        display: 'none',
    },

    toolTip: {
        backgroundColor: '#014758'
    },
    icon: {
        color: '#fff'
    },
    scrollActions : { 

    }

}));


export default useStyles;