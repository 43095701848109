export const extractMessage = resProblem => {
    return resProblem.error_des ? resProblem.error_des : !resProblem.problem ? resProblem.message ? `Server Error: ${resProblem.message}` : "Server Error" : resProblem.problem
}
export const handleEntityDidCatch = (problem) => {
    let messages = [];
    try {
        if (typeof problem === "object") {
            var keys = Object.keys(problem);
            keys.map((key) => {
                problem[key].forEach((message) => {
                    messages.push(message);
                });
            });
        }
        else messages.push(problem);
    } catch (ex) {
        messages.push("Some Thing Went Wrong")
    }
    return messages;
}

export const handleEntityDidSucceeded = (message, context, history) => {
    context.setNotification((currState) => ({
        ...currState,
        open: true,
        severity: 'success',
        message: [...message]
    }))
    if (!!history) history.goBack();
}
