import React from 'react'
import { List } from '@material-ui/core'
import useStyles from './styles'
import { MenuControl } from '../../'
import { useHistory } from 'react-router-dom'
import { useUserContext } from "~/Services/Providers";
import { getUserPermissions, userHavePermission } from "~/Services/Helpers";

const SubMenu = ({ level = 0, ...props }) => {
    const classes = useStyles();
    const history = useHistory();
    const { userContextObj } = useUserContext();
    const permissions = getUserPermissions(userContextObj.role);
    const { data, activeNav, setActiveNav } = props;

    return (
        <List className={classes.subMenu}>
            {data ?
                (!!activeNav.activeTile && data.map((element, index) =>{
                    let ItemPermissions = Array.isArray(element.permission) ? element.permission : [element.permission];
                    return userHavePermission(permissions, ...ItemPermissions)
                        ? <MenuControl
                            type={Array.isArray(element.sections) ? "Collapse" : "Item"}
                            element={element}
                            level={level}
                            key={index}
                            handleClick={() => {
                                setActiveNav(currState => ({
                                    ...currState,
                                    path: element.path
                                }))
                                history.push(element.path)
                            }}
                            activeNav={activeNav}
                            setActiveNav={setActiveNav}
                        /> : <></>
                })) : <></>
            }

        </List>
    )
}

export default SubMenu;