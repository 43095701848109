import get from 'lodash/get';
import { call, put, select } from 'redux-saga/effects';
import { StorageKeys } from '~/Configs/Storage';
import api from '~/Services/Api';
import { getStorage, removeStorage, setStorage } from '~/Services/Helpers';
import AccountActions from '../Actions/Account';

// import StartAuth from "~/Navigation/Auth";
export default {

  *setToken({ token }) {
    yield setStorage(StorageKeys.token, token);
  },

  *checkStatus() {
    const token = yield getStorage(StorageKeys.token);
    
    if (token) {
      yield put(AccountActions.setToken(token));
    } else {
      yield put(AccountActions.getTokenFailed());
    }
  },

  *getProfile() {
    const token = yield select(store => store.account.token);
    const res = yield call(api.account.get, {}, { headers: { authentication_token: token } });

    if (res.ok) {
      const profile = get(res, 'data', null);

      yield put(AccountActions.getProfileSucceeded(profile));
    } else {
      const errors = get(res, 'data.errors', []);

      yield put(AccountActions.getProfileFailed(errors));
    }
  },

  *logout() {   
    yield removeStorage(StorageKeys.token);
    yield removeStorage(StorageKeys.userContext)
    yield put(AccountActions.reset());
  }
};
