/* eslint-disable import/no-anonymous-default-export */

export default {
    active: '',
    data: {
        'Administration': {
            icon: 'supervisor_account',
            EnglishLabel: 'Administration',
            ArabicLabel: 'Administration',
            permissions: ['manage user', 'manage admin', 'manage role', 'public', 'manage request'],
            path: '/dashboard',
            sections: [
                { title: 'Dashboard', icon: 'dashboard', path: '/dashboard', permission: 'public' },
                { title: 'Users', icon: 'group', path: '/administration/users', permission: 'manage user' },
                { title: 'Admins', icon: 'verified_user_outlined', path: '/administration/admins', permission: 'manage admin' },
                { title: 'Roles', icon: 'lock', path: '/administration/roles', permission: 'manage role' },
                { title: 'Requests', icon: 'import_export', path: '/administration/requests', permission: 'manage request' },
                { title: 'Activities', icon: 'remove_red_eye', path: '/administration/activities', permission: 'manage admin activity' },
            ],
        },
        'Store front': {
            icon: 'store_front',
            EnglishLabel: 'Store front',
            ArabicLabel: 'Store front',
            permissions: ['manage category', 'manage sub-category', 'manage application', 'manage product', , 'manage specification', 'manage brand', 'manage section', 'manage package'],
            path: '/store-front/sections',
            sections: [
                { title: 'Sections', icon: 'category', path: '/store-front/sections', permission: 'manage section' },
                { title: 'Categories', icon: 'all_inbox', path: '/store-front/categories', permission: 'manage category' },
                { title: 'Subcategories', icon: 'view_carousel', path: '/store-front/sub-categories', permission: 'manage sub-category' },
                { title: 'Series', icon: 'content_copy', path: '/store-front/series', permission: 'manage series' },
                { title: 'Products', icon: 'dashboard', path: '/store-front/products', permission: 'manage product' },
                { title: 'Applications', icon: 'apps', path: '/store-front/applications', permission: 'manage application' },
                { title: 'Specifications', icon: 'format_list_numbered', path: '/store-front/specifications', permission: 'manage specification' },
                { title: 'Brands', icon: 'loyalty', path: '/store-front/brands', permission: 'manage brand' },
                { title: 'Units', icon: 'extension', path: '/store-front/units', permission: 'manage unit' },
            ],
        },
        'Shop': {
            icon: 'shopping_basket',
            EnglishLabel: 'Shop',
            ArabicLabel: 'Shop',
            permissions: ['manage order', 'manage inquiries', 'manage tender'],
            path: '/shop/manage-orders',
            sections: [
                { title: 'Orders', icon: 'work', path: '/shop/manage-orders', permission: 'manage order' },
                { title: 'Inquiries', icon: 'question_answer', path: '/shop/available-inquiries', permission: 'manage inquiries' },
                { title: 'Tenders', icon: 'gavel', path: '/shop/available-tenders', permission: 'manage tender' },
            ],
        },

        'Jobs': {
            icon: 'work_outline',
            EnglishLabel: 'Jobs',
            ArabicLabel: 'Jobs',
            permissions: ['manage job', 'manage job-application'],
            path: '/recruitment/jobs',
            sections: [
                { title: 'Jobs', icon: 'work', path: '/recruitment/jobs', permission: 'manage job' },
                { title: 'Jobs Applications', icon: 'contacts', path: '/recruitment/job-applications', permission: 'manage job-application' },
            ],
        },

        'General': {
            icon: 'playlist_add_check',
            EnglishLabel: 'General',
            ArabicLabel: 'General',
            permissions: ['manage country', 'manage customer', 'manage slider', 'manage setting', 'manage faq', 'manage contact', 'manage team'],
            path: '/general/customers',
            sections: [
                { title: 'Customers', icon: 'account_circle', path: '/general/customers', permission: 'manage customer' },
                { title: 'Sliders', icon: 'burst_mode', path: '/general/sliders', permission: 'manage slider' },
                { title: 'FAQs', icon: 'contact_support', path: '/general/faqs', permission: 'manage faq' },
                { title: 'Contact requests', icon: 'contact_mail', path: '/general/contacts', permission: 'manage contact' },
                { title: 'Countries', icon: 'language', path: '/general/countries', permission: 'manage country' },
                { title: 'Website info', icon: 'info', path: '/general/website-info', permission: 'manage setting' },
                { title: 'Team', icon: 'people_outline', path: '/general/teams', permission: 'manage team' },
                { title: 'Notifications', icon: 'notifications_active_outlined', path: '/general/notifications', permission: 'manage notification' },
                { title: 'Questions', icon: 'help_outline_outlined', path: '/general/questions', permission: 'manage question' },
            ],
        },

    },
};

