import React from 'react';
import { toAbsoluteUrl } from '~/Services/Helpers';
import useStyles from './styles';
// import './index.css';

const Loader = () => {
    const classes = useStyles();

    return <>
        <div className={classes.filtersLoader}>
            <img src={toAbsoluteUrl("/logo.jpg")} alt="" />
            <span>Loading...</span>
        </div>
    </>
}

export default Loader;