import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import useStyles from './style';
import { toAbsoluteUrl } from '~/Services/Helpers';
import { getAllNotification, makeAsRead } from '../api';
import { CircularProgress } from '@material-ui/core';
import { formatDistance } from 'date-fns';

export default function NotificatinDrawer({ openDrower, setOpenDrower }) {
    const classes = useStyles();
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [serverDate, setServerDate] = useState('');


    const getAllNotifications = () => {
        setLoading(true);
        getAllNotification({ no_pagination: true }, (res) => {
            setLoading(false);
            setServerDate(res.data.date);
            setData(res.data.content);
        }, (error) => { })
    }
    const handleItemClick = async (notfi) => {
        const url = notfi.link;
        const { host } = new URL(url);
        if (notfi.read != 1) {
            makeAsRead(notfi.id, (res) => {
                setOpenDrower(!openDrower);

                if (window.location.host === host) {
                    window.location.href = url ? url : null;
                } else {
                    window.open(url, '_blank');
                }
            })
        } else {
            setOpenDrower(!openDrower);

            if (window.location.host === host) {
                window.location.href = url ? url : null;
            } else {
                window.open(url, '_blank');
            }
        }

    }

    useEffect(() => {
        getAllNotifications();
    }, [])
    return (
        <Drawer
            className={classes.root}
            anchor={'right'}
            open={openDrower}
            onClose={() => setOpenDrower(false)}
        >
            <div className={classes.dropdownHeader}>
                <h2>
                    Notifications
                </h2>
                <div className={classes.seeAll}>
                    <img
                        src={toAbsoluteUrl('/favicon.png')}
                        width={50}
                        height={50}
                    />
                </div>
            </div>
            {loading ? <div className={classes.loader}>
                <CircularProgress size={20} />
            </div> :
                <div className={classes.content}>
                    {data.length > 0 && data.map((notfi) => (<div
                        onClick={() => handleItemClick(notfi)}
                        className={classes.menuItem}
                        style={{ '--my-color-var': notfi.read != 1 ? '#2e89ff' : "" }}
                    >
                        {notfi.leftIcon && <span className={classes.iconButton}>
                            {notfi.leftIcon}
                        </span>}
                        <div className={classes.messageContent}>
                            <div className={classes.title}>
                                {notfi.title}
                            </div>
                            <div>
                                {notfi.message}
                            </div>
                            <span className={classes.iconRight}>
                                {formatDistance(
                                    new Date(notfi.created_at),
                                    new Date(serverDate),
                                    { addSuffix: true }
                                )}
                            </span>
                        </div>
                    </div>))}
                </div>}
        </Drawer>
    );
}