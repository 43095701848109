import { Badge, Button, CircularProgress, IconButton, Menu } from '@material-ui/core';
import { NotificationsActiveOutlined } from '@material-ui/icons';
import { formatDistance } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { toAbsoluteUrl } from '~/Services/Helpers';
import { useUserContext } from "~/Services/Providers";
import { getAllNotification, getAllNotificationCount, makeAsRead, oneSignalInit } from './api';
import NotificatinDrawer from './NotificatinDrawer/index';
import useStyles from './style';
import { useHistory } from 'react-router-dom'

const OneSignalNotifications = (props) => {
    const { userContextObj } = useUserContext();
    const history = useHistory();

    const classes = useStyles();
    const [openDrower, setOpenDrower] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paginateLoading, setPaginateLoading] = useState(false);

    const [pages, setPages] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [done, setDone] = useState(false);

    const [serverDate, setServerDate] = useState('');

    const [badgeContent, setBadgeContent] = useState(0);
    const ref = useRef(null)

    const [anchorEl, setanchorEl] = useState(null)

    const handleClose = (event) => {
        setanchorEl(null)
        setPages(1);

    }
    const handleOpen = (event) => {
        getAllNotifications();
        getAllNotificationsCount();
        setanchorEl(event.currentTarget);
    }

    const SetOpenDrower = () => {
        handleClose();
        setOpenDrower(!openDrower)
    }
    const handelScroll = (e) => {
        if (parseInt(e.target.scrollTop) + parseInt(e.target.clientHeight) >= ref.current.scrollHeight - 150) {
            if ((pages <= totalPages) && !done) {
                setDone(true)
                setPaginateLoading(true)
                getAllNotification({ page: pages, limit: 7 }, (res) => {
                    setData([...data, ...res.data.content])
                    setTotalPages(res.data.paginator.total_page)
                    setPages((curr) => curr + 1);
                    setDone(false);
                    setPaginateLoading(false);
                }, (error) => { })
            }
        }

    }

    const getAllNotifications = () => {
        setLoading(true);
        getAllNotification({ page: pages, limit: 7 }, (res) => {
            setLoading(false);
            setServerDate(res.data.date);
            setData(res.data.content);
            setPages((curr) => curr + 1);
            setTotalPages(res.data.paginator.total_page)
        }, (error) => { })
    }
    const getAllNotificationsCount = () => {
        getAllNotificationCount({}, (res) => {
            setBadgeContent(res.data.content.received_notifications_count);

        }, (error) => {
            console.log(`error`, error)
        })
    }
    const validURL = (str) =>  {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }
    const handleItemClick = (notfi) => {        
        if (notfi.read != 1) {
            makeAsRead(notfi.id, (res) => {
                handleClose();
                if (validURL(notfi.link)) {
                    window.open(notfi.link, '_blank');
                } else {
                    history.push(notfi.link)
                }
            })
        } else {
            handleClose();
            if (validURL(notfi.link)) {
                window.open(notfi.link, '_blank');
            } else {
                history.push(notfi.link)
            }
        }

    }

    useEffect(() => {
        getAllNotificationsCount();
        let Config = {
            appId: "d4a9d65d-f829-4a6d-b50b-c5dc96f470c7",
            subdomainName: 'admin-Laboshop',
            userId: userContextObj.data.id,
            "getAllNotificationsCount": getAllNotificationsCount
        }
        oneSignalInit(Config);
    }, [])

    return (
        <div className={classes.root}>
            <IconButton onClick={handleOpen}>
                <Badge badgeContent={badgeContent} color="secondary">
                    <NotificationsActiveOutlined />
                </Badge>
            </IconButton>
            {openDrower && <NotificatinDrawer openDrower={openDrower} setOpenDrower={setOpenDrower} />}
            <Menu
                style={{ position: "absolute", left: "-100px", top: '50px' }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    onScroll: handelScroll,
                    ref: ref,
                }}
                className={classes.dropdown}
            >
                <div className={classes.dropdownHeader} >
                    Notifications
                    {data.length != 0 && <Button className={classes.seeAll} onClick={() => SetOpenDrower()}>
                        See All
                    </Button>}
                </div>
                {loading ? <div className={classes.loader}>
                    <CircularProgress size={25} />
                </div> :
                    <div className={classes.content}>
                        {data.length > 0 && data.map((notfi) => (<div
                            onClick={() => handleItemClick(notfi)}
                            className={classes.menuItem}
                            style={{ '--my-color-var': notfi.read != 1 ? '#2e89ff' : "" }}
                        >
                            {notfi.leftIcon && <span className={classes.iconButton}>
                                {notfi.leftIcon}
                            </span>}
                            <div className={classes.messageContent}>
                                <div className={classes.title}>
                                    {notfi.title}
                                </div>
                                <div>
                                    {notfi.message}
                                </div>
                                <span className={classes.iconRight}>
                                    {formatDistance(
                                        new Date(notfi.created_at),
                                        new Date(serverDate),
                                        { addSuffix: true }
                                    )}
                                </span>
                            </div>
                        </div>))}
                        {data.length == 0 && <div className={classes.emptyIcon}>
                            <img height="150" src={toAbsoluteUrl('/notifications.svg')} />
                        </div>}
                        {paginateLoading && <div className={classes.loader}><CircularProgress size={25} /> </div>}
                    </div>}
                <div className={classes.footer}>
                    {data.length != 0 && <Button className={classes.viewAll} onClick={() => SetOpenDrower()}>
                        View All Notifications
                    </Button>}
                </div>
            </Menu>
        </div>
    )
}


export default OneSignalNotifications;
