import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import EmptyPage from '~/Components/UI/EmptyPage';
import { isEmpty } from '~/Services/Helpers/isEmpty';
import { StorageKeys } from '~/Configs/Storage';
import { getStorage, getUserPermissions, userHavePermission } from '~/Services/Helpers';
import { useUserContext } from "~/Services/Providers";

function PrivateRoute(props) {
    const { userContextObj } = useUserContext();
    const permissions = !isEmpty(userContextObj.role) ? getUserPermissions(userContextObj.role) : [];
    let ItemPermissions = Array.isArray(props.permission) ? props.permission : [props.permission];
    return (
        getStorage(StorageKeys.token) 
            ?  userHavePermission(permissions, ...ItemPermissions)
                ?  <Route {...props} />
                :  <Route path="/*" component={EmptyPage} exact /> 
            : <Redirect to="/login" exact />
    )
}


export default PrivateRoute

