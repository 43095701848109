import React from 'react'
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab'

function NotificationPopup({ message, severity, vertical, horizontal, open ,handleClose }) {
  
    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            autoHideDuration={5000}
        >
            <Alert elevation={6} severity={severity} >
                {message}
            </Alert>
        </Snackbar>
    )
}

export default NotificationPopup
