//BUG the app crashes when changing the order of the imports
import { Chip, Icon, IconButton } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import ReactHtmlParser from 'react-html-parser';
//FIXME replace this png image with svg
import LazyLoad from 'react-lazyload';
import styles from '~/Screens/styles';
import { fixedStorageLink } from '~/Services/Helpers';
import { toAbsoluteUrl } from './';
import { isEmpty } from './isEmpty';



const onError = ev => {
    ev.target.src = toAbsoluteUrl("/img-placeholder.png");
}

export const evaluateLink = (value) => !isEmpty(value)
    ? <a target='_blank' href={value}><Icon color="primary">link</Icon></a>
    : <></>;



//TODO create a component to handle this logic with default values and styles
const evaluateBooleanChipStyles = {
    fontWeight: 600,
    border: 'solid 1px',
    fontSize: '.75rem',
    padding: 5,
}

export const evaluateBoolean = (value) => value
    ? <Chip variant="outlined" size="small" style={{ color: '#2AA983', background: 'rgb(42 169 131 / 20%)', ...evaluateBooleanChipStyles }} label={"YES"} />
    : <Chip variant="outlined" size="small" style={{ color: '#ED4337', background: 'rgb(237 67 55 / 20%)', ...evaluateBooleanChipStyles }} label={"NO"} />;

export const evaluateChip = (value, styles) => !isEmpty(value) ? <Chip style={!styles ? {} : styles} label={value} /> : <></>

export const evaluateImage = (value, isUser, isShow, style) => <LazyLoad><img
    {...style}
    style={!style ? { width: '50px' } : style}
    style={isShow ? styles.imageView : styles.imageBrowse}
    src={isEmpty(value) ? toAbsoluteUrl("img-placeholder.png") : fixedStorageLink + value}
    onError={onError}
/></LazyLoad>

export const evaluateDate = value => {
    const date = new Date(value);
    if (!isEmpty(value))
        return `${date.toLocaleDateString('en-US')} ${date.toLocaleTimeString('en-US')}`
    else return <></>
}

export const evaluateObject = (value, attribute) => !!value && value[attribute] ? value[attribute] : ''

export const evaluateRequestChips = values => {
    let requests = [];
    if (!isEmpty(values))
        Object.keys(values).map(name => {
            if (values[name] !== 0)
                requests.push(<Chip style={{ margin: '5px' }} label={name.replace("_request_count", "") + ': ' + values[name]} />)
        });
    return requests;
}

export const evaluateChips = (values, styles) => {
    let chips = [];
    if (!isEmpty(values))
        values.map(object => {
            chips.push(<Chip style={!styles ? { margin: '5px' } : styles} label={object.title} />)
        });
    return chips;
}


export const evaluateFile = (value) => !isEmpty(value)
    ? <a style={{ width: '70px' }} target='_blank' href={value}><AttachFileIcon color="primary" /></a>
    : <></>

export const evaluateLanguage = (value) => !isEmpty(value)
    ? <LazyLoad><img style={styles.icon} src={value === "ar" ? toAbsoluteUrl("/uae.svg") : toAbsoluteUrl("/uk.svg")} /></LazyLoad>
    : <></>

export const evaluateImages = (array) => {
    if (isEmpty(array)) return <></>
    const list = [];
    array.map((info) => {
        list.push(evaluateImage(info));
    });
    return list;
}

export const evaluateFiles = (array) => {
    if (isEmpty(array) || array === []) { return <></> }
    const list = [];
    array.map((info) => {
        list.push(evaluateFile(info));
    });
    return list;
}
export const evaluateTextRow = (value) => {
    if (!isEmpty(value)) {
        let temp = '';
        temp = value.length > 50 ? value.substring(0, 50) + "...." : value
        return !!temp.length && !isEmpty(temp) ? ReactHtmlParser(temp) : <></>
    }
    return <></>
}

export const SocialLink = ({ value, children }) => !isEmpty(value)
    ? <a target='_blank' href={value}><IconButton component='span'>{children}</IconButton></a>
    : <></>;

export const FacebookLink = ({ value }) => <SocialLink value={value}><FacebookIcon /></SocialLink>
export const LinkedInLink = ({ value }) => <SocialLink value={value}><LinkedInIcon /></SocialLink>
export const TwitterLink = ({ value }) => <SocialLink value={value}><TwitterIcon /></SocialLink>
