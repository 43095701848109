import * as Yup from "yup";
import reduce from 'lodash/reduce'



export const getValidationSchema = (data) => {
    let validationSchema = reduce(data, (result, value, key) => {
        result[key] = Yup.string().trim().required();
        return result;
    }, {})
    return Yup.object().shape(validationSchema);
}


export const getFormData = (data) => {
    const myformData = [];
    Object.keys(data).forEach((key) => {
        myformData.push({
            name: `${key}`, type: `text`
        });
    })

    return myformData;
}

export const getFormikProps = (data) => {
    let validationSchema = getValidationSchema(data);
    const formikProps = {
        validationSchema,
        formik: {
            onChangeValidate: false,
            onBlurValidate: false,
        },
        initialVals: reduce(data, (result, value, key) => {
            result[key] = " ";
            return result;
        }, {})
    };
    return formikProps;
}


export const DataToQueryParams = (data) => Object.keys(data).reduce((acc ,key , index) => index === 1 
    ? `?${acc}=${data[acc]}&${key}=${data[key]}` 
    :  `${acc}&${key}=${data[key]}`
)