import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: theme.shadows[2],
        width: '95%',
        padding: theme.spacing(4),
        textAlign: 'center',
        display: 'block',
        marginTop: theme.spacing(2),
        borderRadius: 5,
        color: theme.palette.type == "dark" ? "#FFF" : "#000"
    },
    Icon: {
        fontSize: 70
    }
}))


export default useStyles;