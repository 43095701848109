import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    contentLoader: {
        marginLeft: theme.direction === "ltr" ? 20 : "",
        marginRight: theme.direction === "rtl" ? -20 : "",
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        direction: "ltr",
    },
    homeButton: {
        margin: theme.spacing(1),
        marginLeft: theme.direction === "rtl" ? theme.spacing(1) : theme.spacing(1.5),
        marginRight: theme.direction === "ltr" ? theme.spacing(1) : theme.spacing(1.5)
    }

}))

export default useStyles;