import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root: {
        display : 'flex',
        marginBottom: '2em',
    },
    divider: {
        display: 'block',
        margin: `${theme.spacing(3)}px 0`,
    },
    field: {
        margin: '10px',
        position: 'relative'
    },
    breadCrumb: {
        display: 'block',
        fontSize: 12,
        color: theme.palette.text.secondary,
        '& p': {
            display: 'block',
            margin: 0,
            '& span': {
                textTransform: 'capitalize',
                marginLeft: 5,
            },
            '& a': {
                color: theme.palette.text.primary,
                textDecoration: 'none',
                margin: '0 5px'
            }
        },
    }

}))


export default useStyles;