import { Button, Tooltip } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import React from 'react';
import useStyles from './styles';

const ScrollTop = () => {
    const classes = useStyles();
    return (
        <div className={classes.root} >
            <span>Scroll To</span>
            <div className={classes.scrollActions}>
                <Tooltip title={'Back To Top'} placement="left">
                    <Button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                        className={classes.toolTip}>
                        <ArrowUpward className={classes.icon} />
                    </Button>
                </Tooltip>
                <Tooltip title={'Go to Bottom'} placement="right">
                    <Button onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}
                        className={classes.toolTip}>
                        <ArrowDownward className={classes.icon} />
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
}

export default ScrollTop;