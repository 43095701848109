import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  online: {
    backgroundColor: 'lightgreen'
  },
  bussy: {
    backgroundColor: 'red'
  },
  idle: {
    backgroundColor: 'amber'
  },
  offline: {
    backgroundColor: 'grey'
  },

  avatarHead: {
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },

  dotStatus: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    display: 'inline-block',
    borderRadius: '50%',
    border: '1px solid #fff',
    marginRight: theme.spacing(1),
    '&$pinned': {
      position: 'absolute',
      bottom: 3,
      left: 60,
    }
  },
  pinned: {},

  statusMenu: {
    '& ul': {
      paddingTop: 0,
    },
    '& li': {
      width: 180
    }
  },

  profile: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  name: {
    textAlign: 'left',
    marginLeft: theme.spacing(1),
    fontSize: 14,
    '& h5': {
      margin: 0
    }
  },

  dotStatus: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    display: 'inline-block',
    borderRadius: '50%',
    border: '1px solid #fff',
    marginRight: theme.spacing(1),
    '&$pinned': {
      position: 'absolute',
      bottom: 3,
      left: 35,
    }
  },
})
)

export default useStyles;