import { makeStyles, fade } from '@material-ui/core/styles'


const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    margin: 0,
    // display: 'block',
    top : `-${theme.spacing(1)}px`,
    marginRight: theme.spacing(2),
    // [theme.breakpoints.down('sm')]: {
    //   display: 'block'
    // }
  },
  Logo: {
    position : 'relative' , 
    top : 12,
    width: 104,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },

  },
  menuItem:{ 
    display: 'inline-block',
    margin: `0 ${theme.spacing(2)}px`, 
    "& $menuIcon" : {
       
    }
  },
  menuItemIcon : {
    display: 'inline-block',
  },
  menuIcon : {
    color : theme.palette.common.white
  },
  
}));

export default useStyles;