const styles = {
    imageBrowse: {
        width: "90px", height: "90px",
        marginRight: '10px',
    },
    imageView: {
        margin: '0px auto',
        display: 'block',
        textAlign: "center",
        height: "150px",
        width: "150px"
    },
    icon: {
        marginLeft: '20px',
        display: 'block',
        width: '40px'
    },
}
export default styles;