import { makeStyles ,fade } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    active: {},
    icon: {},
    text: {},
    menuHead: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.direction === "ltr" ? theme.spacing(1) : "",
        marginRight: theme.direction === "rtl" ? theme.spacing(1) : "",
        borderRadius: 4,
        '&:hover': {
            background: theme.palette.action.hover,
        },
        '&$active': {
            '&:before': {
                content: '""',
                position: 'absolute',
                width: 5,
                height: 68,
                borderRadius: 5,
                top: 0,
                left: theme.direction === "ltr" ? theme.spacing(1) * -1 : "",
                right: theme.direction === "rtl" ? theme.spacing(1) * -1 : "",
                background: theme.palette.primary.dark
            },
            '& $icon, $text': {
                color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
            },
        },
        '& $icon': {
            color: fade(theme.palette.primary.light, 0.24),
            display: 'block',
            marginBottom: theme.spacing(1),
            fontSize: 32,
        },
        '& $text': {
            width: 80,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
            fontSize: 12,
            color: theme.palette.text.secondary
        }
    },

}));

export default useStyles;