import { Avatar, ButtonBase, Menu, MenuItem } from '@material-ui/core';
import { ExitToApp, Person } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { useDispatch } from 'react-redux'
import AccountActions from '~/Redux/Actions/Account';
import { USER_INITIAL_STATE, useUserContext } from '~/Services/Providers';


function MenuProfile() {
    const [anchorEl, setanchorEl] = useState(null)
    const { userContextObj, setUserContext } = useUserContext();
    const dispatch = useDispatch();
    const classes = useStyles();

    let { name } = userContextObj.data


    const handleOpen = (event) => {
        setanchorEl(event.currentTarget);
    }

    const handleClose = (event) => {
        setanchorEl(null)
    }

    const handleLogout = () => {
        dispatch(AccountActions.logout());
        setUserContext({ ...USER_INITIAL_STATE });
    }

    return (
        <div>
            <ButtonBase className={classes.avatarHead} onClick={handleOpen}>
                <Avatar
                    alt={name}
                    src={name}
                    className={classNames(classes.avatar, classes.bigAvatar)}
                />
            </ButtonBase>
            <Menu style={{ position: "absolute", left: "-40px", top: '50px' }}
                id="status-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.statusMenu}
            >
                <MenuItem className={classes.profile}>
                    <Avatar
                        alt={name}
                        src={name}
                        className={classNames(classes.avatar, classes.bigAvatar)}
                    />
                    <div className={classes.name}>
                        <h5> {name} </h5>
                    </div>
                </MenuItem>

                <MenuItem onClick={handleLogout} >
                    <ExitToApp color="error" /> &nbsp; Logout
                </MenuItem>

            </Menu>
        </div >
    );
}

MenuProfile.defaultProps = {
    anchorEl: null,
    isLogin: false,
};

export default MenuProfile;
