import { Icon, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core'
import React, { useState } from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { MenuControl } from '..';
import { useHistory } from 'react-router-dom'
import useStyles, { Styles } from './styles'

const MenuCollapse = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { icon, name, active, handleClick, activeNav, setActiveNav, data, level } = props
    const [open, setOpen] = useState(false)

    const handleCollapse = () => {
        setOpen(!open)
        handleClick();
    }
    return (
        <div style={Styles.padding(level)}>
            <ListItem selected={active} onClick={handleCollapse} button>
                <ListItemIcon style={{ minWidth: 30 }} >
                    <Icon className={classes.Icon} > {icon} </Icon>
                </ListItemIcon>
                <ListItemText className={classes.text} primary={name} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {
                    data.map((element, index) => <MenuControl
                        handleClick={() => {
                            setActiveNav(currState => ({
                                ...currState,
                                path: element.path
                            }))
                            history.push(element.path)
                        }}
                        type={Array.isArray(element.children) ? "Collapse" : "Item"}
                        level={level + 1}
                        element={element}
                        key={index}
                        activeNav={activeNav}
                        setActiveNav={setActiveNav}
                    />)
                }
            </Collapse>
        </div>

    )
}

export default MenuCollapse
