import { isEmpty } from '~/Services/Helpers/isEmpty'
import { StorageKeys } from '~/Configs/Storage';
const userID = JSON.parse(window.localStorage.getItem(StorageKeys.userContext))?.data?.id;

export const getStaticSidePreferences = title => {
    return JSON.parse(window.localStorage.getItem(`PAGINATOR_${userID}_${title}`));
}
export const saveStaticSidePreferences = (tableState, title) => {
    window.localStorage.setItem(`PAGINATOR_${userID}_${title}`, JSON.stringify({ current_page: tableState.page + 1, page: tableState.page, rowsPerPage: tableState.rowsPerPage, limit: tableState.rowsPerPage }));
}

export const saveSSRPreferences = (tableState, title) => {
    window.localStorage.setItem(`PAGINATOR_${userID}_${title}`, JSON.stringify({ current_page: tableState.page + 1, page: tableState.page, rowsPerPage: tableState.rowsPerPage, limit: tableState.rowsPerPage }));
}

const SSRPaginator = {
    total_count: 0,
    limit: 50,
    current_page: 1
};
    
export const getSSRPreferences = (title) => {
    return isEmpty(getStaticSidePreferences(title)) ? SSRPaginator : getStaticSidePreferences(title);
}