import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	filtersLoader: {
		position: 'fixed',
		top: '0',
		left: '0',
		height: '100vh',
		width: '100vw',
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		zIndex: '100',
		color: theme.palette.type === "light" ? "#000" : "#fff",
		'& img': {
			width: '50px',
			height: '50px',
			margin: '0.5em',
			animation: `$nkSpinner 0.9s infinite linear`,
		},
	},
	'@keyframes nkSpinner': {
		'100%': {
			transform: 'rotate(360deg)',
		}
	}

}))

export default useStyles
