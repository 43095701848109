import React, { useState, createContext, useContext } from "react";
import { StorageKeys } from '~/Configs/Storage';

export const USER_INITIAL_STATE = {
  isAuthenticated: false,
  token: "",
  data: {},
  editValues: null,
};

// initializing context
export const UserContext = createContext(USER_INITIAL_STATE);

// setting a helper function to use context
export const useUserContext = () => useContext(UserContext);

export function UserContextProvider({ children }) {
  const [userContextObj, setUserContext] = useState(USER_INITIAL_STATE);
  const setContext = (data) => {
    setUserContext((currContext) => ({ ...currContext, ...data }));
    window.localStorage.setItem(StorageKeys.userContext, JSON.stringify({ ...userContextObj, ...data }));
  };
  return (
    <UserContext.Provider value={{ userContextObj, setUserContext: setContext }}>
      {children}
    </UserContext.Provider>
  );
}
