import { DataToQueryParams, extractQueryParam } from '~/Services/Helpers';

const normalFuntion = data => data;

export const paginatorHelperHandler = (entity, info, addtional = {}, customExtractor = normalFuntion) => {
    entity.get({
        queryParams: DataToQueryParams({
            page: info?.current_page,
            limit: info?.limit,
            sort: JSON.stringify(info?.sort ?? {}),
            ...addtional,
            ...extractQueryParam(customExtractor)
        })
    });
}

export const staticSideHelperHandler = (entity, customExtractor = normalFuntion) => {
    entity.get({
        queryParams: DataToQueryParams({
            ...extractQueryParam(customExtractor),
        })
    });
}
