import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions(
  {
    checkFavorite: null,
    checkUnfavorite: null,

    setToken: ['token'],
    update: ['data'],

    checkStatus: null,

    getTokenSucceeded: ['token'],
    getTokenFailed: null,

    getProfile: null,
    getProfileSucceeded: ['data'],
    getProfileFailed: ['errors'],

    logout: null,

    reset: null
  },
  {
    prefix: 'Account/'
  }
);

export const AccountTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  token: null,
  loading: false,
  errors: null,
  profile: {}
});

/* ------------- Reducers ------------- */

export const setToken = (state, { token }) => state.merge({ token });

export const update = (state, { data }) =>
  state.merge({
    profile: {
      ...state.profile,
      ...data
    }
  });

export const checkStatus = state => state.merge({ loading: true });

export const getTokenSucceeded = (state, { token }) => state.merge({ loading: false, token });

export const getTokenFailed = state => state.merge({ loading: false });

export const getProfile = state => state.merge({ loading: true });

export const getProfileSucceeded = (state, { data }) => state.merge({ loading: false, profile: data });

export const getProfileFailed = (state, { errors }) => state.merge({ loading: false, errors: errors || true });

export const logout = state => state.merge({ token: null });

export const reset = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_TOKEN]: setToken,
  [Types.UPDATE]: update,

  [Types.CHECK_STATUS]: checkStatus,

  [Types.GET_TOKEN_SUCCEEDED]: getTokenSucceeded,
  [Types.GET_TOKEN_FAILED]: getTokenFailed,

  [Types.GET_PROFILE]: getProfile,
  [Types.GET_PROFILE_SUCCEEDED]: getProfileSucceeded,
  [Types.GET_PROFILE_FAILED]: getProfileFailed,

  [Types.LOGOUT]: logout,

  [Types.RESET]: reset
});
