import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    subMenu: {
        marginLeft: theme.direction === "ltr" ? 15 : "",
        marginRight: theme.direction === "rtl" ? 15 : "",
        width: '100%',
        height: `calc(100vh - 64px)`,
        overflowY: 'scroll',
        '&:hover': {
            transition: theme.transitions.create('overflow-y', {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.complex
            }),
        }
    },
}))

export default useStyles;