import React from "react";
import { Icon } from "@material-ui/core";
import { messages } from "./data";
import useStyles from "./styles";

function EmptyPage({ type }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Icon className={classes.Icon}> {messages[type].icon}</Icon>
      <h2> {messages[type].message} </h2>
    </div>
  );
}

EmptyPage.defaultProps = {
  type: "notBuilt",
};
export default EmptyPage;
