export const genericDropdownParser = (idKey, nameKey) => (data) => {
    let parsedData = []
    Array.isArray(data) && data.forEach(entry => {
        parsedData = [...parsedData,
        {
            name: eval(`entry.${nameKey}`),
            id: eval(`entry.${idKey}`)
        }
        ]
    });
    return parsedData;
}