import React from 'react';
import { useNotification } from '~/Services/Providers';
import NotificationPopup from './NotificationPopup';
function NotificationService() {
    const { Notification, setNotification } = useNotification();
  //  const { message, severity, vertical, horizontal, open } = Notification;
    const handleClose = () => {
        setNotification((currState) => ({ ...currState, open: false }))
    }

    return <NotificationPopup
        {...Notification} handleClose={handleClose}
    />

}

export default NotificationService;
