import axios from "axios";
import { create } from "apisauce";
import ApiConfigs from "../Configs/Api";

const axiosInstance = axios.create({
  baseURL: ApiConfigs.apiUrl,
  timeout: ApiConfigs.timeout,
  headers: {
    Accept: `application/json;v=${ApiConfigs.version}`,
    "Content-Type": "application/json",
  },
});

const Api = create({ axiosInstance: axiosInstance });
const CancelToken = axios.CancelToken;
const requestsSourceCancellation = CancelToken.source();

const endpoints = {
  account: {
    login: (data, headers) =>
      Api.post("admin/login", data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    register: (data, headers) =>
      Api.post("auth/signUp", data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    get: (data, headers) =>
      Api.get("admin/profile", data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    dashboard: (data, headers) =>
      Api.get("admin/dashboard", data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    resetPassword: (data, headers) =>
      Api.post(
        `admin/change_password?oldPassword=${data.oldPassword}&Password=${data.password}`,
        {},
          {
              ...headers,
              cancelToken: requestsSourceCancellation.token,
          }
      ),
  },
  users: {
    getAll: (data, headers) =>
        Api.post(`admin/filter-users`, data, {
            ...headers,
            cancelToken: requestsSourceCancellation.token,
        }),
    getOne: (data, headers) =>
      Api.get(
        `admin/users/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/users`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    updateUser: (data, headers) =>
      Api.patch(`admin/users/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/users/delete/${data}`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  series: {
    getAll: (data, headers) =>
      Api.get(
        `admin/series?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/series/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/series`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/series/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/series/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  admins: {
    getAll: (data, headers) =>
      Api.get(
        `admin/admins?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/admins/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/admins`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/admins/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/admins/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  jobs: {
    getAll: (data, headers) =>
      Api.get(
        `admin/jobs?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/jobs/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/jobs`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/jobs/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/jobs/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  contacts: {
    getAll: (data, headers) =>
      Api.get(
        `admin/contacts?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/contacts/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/contacts`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    updateUser: (data, headers) =>
      Api.patch(`admin/contacts/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/contacts/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  activities: {
    getAll: (data, headers) =>
      Api.get(
        `admin/admins-activities${data.queryParams}&no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/admins-activities/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
  },
  brands: {
    getAll: (data, headers) =>
      Api.get(
        `admin/brands?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/brands/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/brands`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    updateBrand: (data, headers) =>
      Api.patch(`admin/brands/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/brands/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  jobsApplications: {
    getAll: (data, headers) =>
      Api.get(
        `admin/job-applications?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/job-applications/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    delete: (data, headers) =>
      Api.delete(`admin/job-applications/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  applicationsProducts: {
    getAll: (data, headers) =>
      Api.get(
        `admin/applications/${data.id}/products?page=${data.page}&limit=${data.limit}${data.filterQuery}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/applications/${data.id}/products`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.put(`admin/applications/${data.id}/products`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  applications: {
    getAll: (data, headers) =>
      Api.get(
        `admin/applications?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/applications/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/applications`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/applications/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/applications/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },

  countries: {
    getAll: (data, headers) =>
      Api.get(
        `admin/countries?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/countries/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/countries`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/countries/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/countries/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  teams: {
    getAll: (data, headers) =>
      Api.get(
        `admin/teams?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/teams/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/teams`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.put(`admin/teams/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/teams/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  notifications: {
    getAll: (data, headers) =>
      Api.get(
        `admin/all-notifications?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/notifications/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/notifications`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/notifications/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  questions: {
    getAll: (data, headers) =>
      Api.get(
        `admin/questions`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/questions/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    delete: (data, headers) =>
      Api.delete(`admin/questions/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  answers: {
    getOne: (data, headers) =>
      Api.get(
        `admin/answers/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/answers`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/answers/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/answers/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  bundles: {
    getAll: (data, headers) =>
      Api.get(
        `admin/bundles?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getByProduct: (data, headers) =>
      Api.get(
        `admin/all-bundles/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/bundles/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/bundles`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.put(`admin/bundles/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/bundles/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  sliders: {
    getAll: (data, headers) =>
      Api.get(
        `admin/sliders?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/sliders/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/sliders`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/sliders/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/sliders/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  packages: {
    getAll: (data, headers) =>
      Api.get(
        `admin/units?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/units/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/units`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/units/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/units/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  unitSizes: {
    getAll: (data, headers) =>
      Api.get(
        `admin/unit-sizes`,
        {
          ...data,
        },
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/unit-sizes/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/unit-sizes`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/unit-sizes/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/unit-sizes`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  roles: {
    getAll: (data, headers) =>
      Api.get(
        `admin/roles`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/roles/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/roles`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/roles/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/roles/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  subCategories: {
    getAll: (data, headers) =>
      Api.get(
        `admin/categories?type=sub&no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/categories`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  tenders: {
    getAllTenders: (data, headers) =>
      Api.get(
        `admin/tenders`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getAllTendersOrders: (data, headers) =>
      Api.get(
        `admin/tender-orders`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOneTender: (data, headers) =>
      Api.get(
        `admin/show-tender-quotation/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOneTenderOrder: (data, headers) =>
      Api.get(
        `admin/tender-orders/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    changeStatusTender: (data, headers) =>
      Api.put(
        `admin/update-tender-quotation/${data.id}?status=${data.status}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    changeStatusTenderOrder: (data, headers) =>
      Api.put(`admin/update-tender-order/${data.id}`, data.data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  media: {
    add: (data, headers) =>
      Api.post(`admin/media-upload`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) => {
      return Api.delete(`admin/media-upload-delete`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      });
    },
  },
  sections: {
    getAll: (data, headers) =>
      Api.get(
        `admin/sections?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/sections/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/sections`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    updateSection: (data, headers) =>
      Api.patch(`admin/sections/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/sections/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  settings: {
    getAll: (data, headers) =>
      Api.get(
        `admin/settings?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/settings`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.put(`admin/settings/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/settings/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  requests: {
    getAll: (data, headers) =>
      Api.get(
        `admin/requests?page=${data.page}&limit=${data.limit}${data.queryFilter}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/requests/${data.id}?page=${data.page}&limit=${data.limit}${data.queryFilter}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    update: (data, headers) =>
      Api.post(`admin/requests/${data.id}/request-status`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  addresses: {
    getAll: (data, headers) =>
      Api.get(
        `admin/addresses`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/address/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/addresses`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/addresses/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/addresses/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  compatibleProducts: {
    getAll: (data, headers) =>
      Api.get(
        `admin/products/${data.id}/compatibles?page=${data.page}&limit=${data.limit}${data.filterQuery}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/products/${data.id}/compatibles`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    uploadExcelFile: (data, headers) =>
      Api.post(`admin/products/${data.id}/compatibles/import`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.put(`admin/products/${data.id}/compatibles`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  products: {
    getAll: (data, headers) =>
      Api.post(`admin/filter-products`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    getAllCompatibleProducts: (data, headers) => {
      return Api.get(
        `admin/products/${data.id}/compatibles?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      );
    },
    post: (data, headers) => {
      if (data.isRequest) {
        return Api.post(`admin/requests/${data.id}/request-status`, data.body, {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        });
      } else {
        return Api.post(`admin/products`, data, {
          ...headers,
          cancelToken: requestsSourceCancellation.token,
        });
      }
    },
    generateSignture: (data, headers) =>
      Api.get(
        `admin/generate-signature/product/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    bulk: (data, headers) =>
      Api.post(`admin/bulk-action`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    disable: (data, headers) =>
      Api.put(`admin/review/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.put(`admin/products/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    getOne: (data, headers) =>
      Api.get(
        `admin/products/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    sync: (data, headers) =>
      Api.get(
        `admin/odoo/sync-product/${data.id}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    delete: (data, headers) =>
      Api.delete(`admin/products`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  bulk: {
    action: (data, headers) =>
      Api.post(`admin/bulk-action`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  specifications: {
    getAll: (data, headers) =>
      Api.get(
        `admin/specifications?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/specifications/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/specifications`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/specifications/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/specifications/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    getSpecific: (data, headers) =>
      Api.get(
        `admin/specific/specifications?Model_type=${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
  },
  inquiries: {
    getAll: (data, headers) =>
      Api.get(
        `admin/inquiries`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/specifications/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
  },
  customers: {
    getAll: (data, headers) =>
      Api.get(
        `admin/customers?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/customers/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/customers`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    update: (data, headers) =>
      Api.patch(`admin/customers/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/customers/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  categories: {
    get: (data, headers) =>
      Api.get(
        `admin/categories?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/categories/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/categories`, data, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/categories/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    put: (data, headers) =>
      Api.put(`admin/categories/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    patch: (data, headers) =>
      Api.patch(`admin/categories/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  faqs: {
    get: (data, headers) =>
      Api.get(
        `admin/faqs?no_pagination=true`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/faqs/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    add: (data, headers) =>
      Api.post(`admin/faqs`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(`admin/faqs/${data}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    put: (data, headers) =>
      Api.put(`admin/faqs/${data.id}`, data.editedData, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    patch: (data, headers) =>
      Api.patch(`admin/faqs/${data.id}`, data.editedData, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
  manageOrders: {
    get: (data, headers) =>
      Api.get(
        `admin/orders?page=${data.page}&limit=${data.limit}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(
        `admin/orders/${data}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
  },
  dynamicComponent: {
    get: (data, headers) =>
      Api.get(
        `${data.uri}`,
        {},
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data = { id: {} }, headers) =>
      Api.get(`${data.uri}`, data.id, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    post: (data, headers) =>
      Api.post(`${data.uri}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },

  GenericHandlers: {
    get: (data, headers) =>
      Api.get(
        data.uri || `admin/${data.type}?page=${data.page}&limit=${data.limit}`,
        data.body,
        { ...headers, cancelToken: requestsSourceCancellation.token }
      ),
    getOne: (data, headers) =>
      Api.get(data.uri || `admin/${data.type}/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    post: (data, headers) =>
      Api.post(data.uri || `admin/${data.type}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    delete: (data, headers) =>
      Api.delete(data.uri || `admin/${data.type}/${data.id}`, data.body, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    put: (data, headers) =>
      Api.put(data.uri || `admin/${data.type}/${data.id}`, data.editedData, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
    patch: (data, headers) =>
      Api.patch(data.uri || `admin/${data.type}/${data.id}`, data.editedData, {
        ...headers,
        cancelToken: requestsSourceCancellation.token,
      }),
  },
};

export default endpoints;
