export const userHavePermission = (userPermission, ...permissions) => {
    if (permissions.includes('public')) return true;
    return permissions.some(permission => userPermission.some(userPermission => userPermission === permission))
}

export const getUserPermissions = (roles = []) => {
    let permissions = []
    for (const role of roles) {
        const rolesPermission = [];
        role.permissions.forEach(permission => {
            rolesPermission.push(permission.name)
        });
        permissions = [...permissions, ...rolesPermission];
    }
    return permissions;
}

export const getActivePermitedPath = (userPermission, data) => {
    if (typeof data.path === "object") {
        let path = '';
        data.path.forEach(activePath => {
            if (userHavePermission(userPermission, activePath)) {
                data.sections.forEach(section => {
                    //FIXME use isEmpty to check if the value not valid
                    if (typeof section.permission === "object" && section.permission.indexOf(activePath) !== -1) {
                        path = section.path;
                    } else if (section.permission === activePath)
                        path = section.path
                })
            }
        });
        return path;
    } else return data.path;
}