import { upperFirst } from "lodash"

function _sectionsArrayLengthCheck(data) {
    if (!data.children) {
        return data
    }
    if (data.children.length === 0) {
        return false
    }
    let haveSections = []
    for (let i = 0; i < data.children.length; i++) {
        haveSections[i] = _sectionsArrayLengthCheck(data.children[i])
    }
    let children = []
    for (let i = 0; i < data.children.length; i++) {
        if (haveSections[i]) { children.push(haveSections[i]) }
    }
    if (children[0])
        return { ...data, path: children[0].path, children }
    else {
        return { ...data, children }
    }
    // return { ...data, activeItem: sections[0].path, sections }
}
function _permissionCheck(data, permissions) {
    if (data.children && Array.isArray(data.children)) {
        let children = []
        for (let i = 0; i < data.children.length; i++) {
            let havePermission = _permissionCheck(data.children[i], permissions)
            if (havePermission)
                children.push(havePermission)
        }
        return { ...data, children }
    }
    else {

        return data
    }

}
export const upperCaseEachWord = data => {
    const array = data.split(' ');
    let temp = [];
    array.map(item=>{
        temp.push(upperFirst(item));
    });
    return temp.join(' ');
    
}
export const normalizeRoutData = (data = {}, permissions) => {
    let permissionCheckObj = {}
    let ArrayLengthCheck = {}
    Object.keys(data).forEach((key) => {
        //step1: looping over navData and start the permission Checker
        permissionCheckObj[key] = _permissionCheck(data[key], permissions)
    })
    //step2: Check if navData levels have sections 
    Object.keys(permissionCheckObj).forEach((key, index) => {
        let Data = _sectionsArrayLengthCheck(permissionCheckObj[key])
        if (Data) {
            ArrayLengthCheck[key] = Data
        }
    })
    return ArrayLengthCheck
}

export const normalizePatchData = (data) => {
    let normalizedData = [];

    Object.keys(data).forEach((key) => {
        normalizedData.push({
            op: "replace",
            path: `/${key}`,
            value: data[key],
        })
    })

    return normalizedData;
}
export const DataToColumns = (data, customOptions) => {
    let columns = []
    if (Array.isArray(data) && data.length !== 0) {
        Object.keys(data[0]).forEach((key, index) => {
            columns[index] = {
                name: `${key}`, label: `${key}`, options: {
                    filter: true, sort: true, display: index > 10 ? false : true,
                    customBodyRender: (value, tableMeta, updateValue) => { return !value ? "No Record" : value }, ...customOptions[key]
                }
            }

        })
    }
    return columns
}

export const normalizeReceivedData = data =>{
    let normalizedData = {};
    let DocumentType = data;
    //removing null
    Object.keys(DocumentType).forEach(key => {
        if (DocumentType[key] === null) normalizedData[key] = '';
        else normalizedData[key] = DocumentType[key];
    })
    return normalizedData;
}

export const removeEmptyStringKeys = data => {
    let normalizedData = {};
    Object.keys(data).forEach(key => {
        if (data[key] !== '') normalizedData[key] = data[key];
    })
    return normalizedData;
}