import React from 'react';
import './index.css';
import { toAbsoluteUrl } from '~/Services/Helpers';

const GeneralLoader = () => <div id="loader">
    <div className="loader-wrapper">
        <div className="loader-container">
            <div>
                <img style={{ width: "200px" }} src={toAbsoluteUrl('/logo.svg')} />
            </div>
            <div className="cs-loader">
                <div className="cs-loader-inner">
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                </div>
            </div>
        </div>
    </div>
</div>
export default GeneralLoader;