import { values } from "mobx";
import { isEmpty } from "./isEmpty";

const normalFuntion = (data) => data;

const isJsonString = (string) => {
  try {
    let expectedToBeJSON = jsonParseFrom64BaseEncoding(string);
    if (!expectedToBeJSON.id && expectedToBeJSON.id !== "") throw new Error();
  } catch (e) {
    return false;
  }
  return true;
};

export const extractQueryParam = (customFuction = normalFuntion) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const keys = Object.keys(params);
  let object = {};
  keys.forEach((attribute) => {
    if (params[attribute] !== undefined && params[attribute] !== null) {
      if (isJsonString(params[attribute])) {
        let value = jsonParseFrom64BaseEncoding(params[attribute]);
        let newObject = {
          name: value?.name,
          id: attributeChecking(value.id, attribute),
        };
        object = { ...object, [attribute]: { ...newObject } };
      } else {
        object = {
          ...object,
          ...{
            [attribute]: attributeChecking(
              stringParseFrom64BaseEncoding(params[attribute]),
              attribute
            ),
          },
        };
      }
    }
  });
  return customFuction(object);
};

const jsonParseFrom64BaseEncoding = (object) =>
  JSON.parse(decodeURIComponent(escape(window.atob(object))));
const stringParseFrom64BaseEncoding = (string) =>
  decodeURIComponent(escape(window.atob(string)));
const attributeChecking = (value, attribute) => {
  let object = {};
  if (isEmpty(value)) object[attribute] = value;
  else if (!isNaN(value)) object[attribute] = parseFloat(value);
  else if (value === "false" || value === "true")
    object[attribute] = value === "true";
  else object[attribute] = value;
  return object[attribute];
};
