import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 275;

const useStyles = makeStyles((theme) => {
    return {
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '4px 8px',
            ...theme.mixins.toolbar,
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: theme.palette.background.default,
            overflow: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: 110,
            [theme.breakpoints.up('sm')]: {
                width: 115,
            },
        },

    }
});

export default useStyles;