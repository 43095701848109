/* eslint-disable import/no-anonymous-default-export */
import { call, put } from "redux-saga/effects";
import { StorageKeys } from "~/Configs/Storage";
import api from "~/Services/Api";
import { extractMessage, getStorage } from "~/Services/Helpers";
import AccountActions from "../Actions/Account";
import EntityActions from "../Actions/Entity";

export default {

  *get({ id, data }) {

    const token = getStorage(StorageKeys.token);
    try {
      if (id === 'GenericDropdown') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.dynamicComponent.get, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, { content: res.data.content, uri: data.uri, code: data.code }));
        } else {
          yield put(EntityActions.getFailed(id, { code: data.code, message: extractMessage(res.data) }));
        }
      }
      if (id === 'DynamicFill') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.dynamicComponent.get, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, { content: res.data.content }));
        } else {
          yield put(EntityActions.getFailed(id, { code: data.code, message: extractMessage(res.data) }));
        }
      }
      if (id === 'BrowseUsers') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.users.getAll, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {

            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseSeries') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.series.getAll, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {

            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'AddressForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.addresses.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {

            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'DynamicInput') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.GenericHandlers.get, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, { content: res.data.content, uri: data.uri, code: data.code, parentID: data.parentID }));
          } else {
            yield put(EntityActions.getFailed(id, { code: data.code, message: extractMessage(res.data) }));
          }
        }
      }
      if (id === 'BrowseAdmins') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.admins.getAll, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {

            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseAddresses') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.addresses.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {

            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseRequests') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.requests.getAll, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {

            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'RequestAction') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.requests.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {

            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseCategories') {
        const res = yield call(api.categories.get, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseCustomers') {
        const res = yield call(api.customers.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseWebsiteInfo') {
        const res = yield call(api.settings.getAll, { type: "settings", ...data }, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseManageOrders') {
        const res = yield call(api.manageOrders.get, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseSpecifications') {
        const res = yield call(api.specifications.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseFAQs') {
        const res = yield call(api.faqs.get, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseSubCategories') {
        const res = yield call(api.subCategories.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'TenderOrderAction') {
        const res = yield call(api.tenders.getOneTenderOrder, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseSections') {
        const token = getStorage(StorageKeys.token);

        if (token) {
          const res = yield call(api.sections.getAll, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowsePackages') {
        const token = getStorage(StorageKeys.token);

        if (token) {
          const res = yield call(api.packages.getAll, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseUnitSizes') {
        const token = getStorage(StorageKeys.token);

        if (token) {
          const res = yield call(api.unitSizes.getAll, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseJobsApplications') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.jobsApplications.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'ViewJobApplication') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.jobsApplications.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseApplications') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.applications.getAll, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseTenders') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.tenders.getAllTenders, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseTenderOrders') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.tenders.getAllTendersOrders, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'ShowTender') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.tenders.getOneTender, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseSliders') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.sliders.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseTeams') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.teams.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseNotifications') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.notifications.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseQuestions') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.questions.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseBundles') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.bundles.getByProduct, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseRoles') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.roles.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseProducts') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.products.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseInquiries') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.inquiries.getAll, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseBrands') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.brands.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseCountries') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.countries.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'GenericDropdownMultiple') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.GenericHandlers.get, data, {
          headers: {
            Authorization: token,
          },
        });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, { ...res.data, uri: data.uri, code: data.code }));
        } else {

          yield put(EntityActions.getFailed(id, { code: data.code, message: extractMessage(res.data) }));
        }
      }
      if (id === 'BrowseJobs') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.jobs.getAll, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }

      }
      if (id === 'UserForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.users.getOne, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'AdminForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.admins.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'ProductForm' && data.compatible_products !== true) {
        const token = getStorage(StorageKeys.token);
        let isSignutre = !!data.type;
        const requestedData = isSignutre ? data.id : data;
        const request = isSignutre ? api.products.generateSignture : api.products.getOne;
        if (token) {
          const res = yield call(request, requestedData, { headers: { Authorization: token } });
          if (res.ok) {
            const content = isSignutre ? { body: res.data.content, type: 0 } : res.data.content
            yield put(EntityActions.getSucceeded(id, {content , compatible_products:false}));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'ProductForm' && data.compatible_products === true) {
        const token = getStorage(StorageKeys.token);
        if (token) {

          const res = yield call(api.products.getAllCompatibleProducts, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, {content: res.data.content , compatible_products:true}));
          } else {

            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'RoleForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.roles.getOne, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {

            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'SectionForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.sections.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'ApplicationForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.applications.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'SliderForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.sliders.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'TeamForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.teams.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'NotificationForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.notifications.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'ViewQuestion') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.questions.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BundleForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.bundles.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrandForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.brands.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'Dashboard') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.account.dashboard, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'CountryForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.countries.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'SeriesForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.series.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'JobForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.jobs.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'CategoriesForm' || id === 'SubCategoriesForm') {
        const res = yield call(api.categories.getOne, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'CustomersForm') {
        const res = yield call(api.customers.getOne, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'PackageForm') {
        const res = yield call(api.packages.getOne, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'UnitSizeForm') {
        const res = yield call(api.unitSizes.getOne, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'ManageOrdersForm') {
        const res = yield call(api.manageOrders.getOne, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'SpecificationsForm') {
        const res = yield call(api.specifications.getOne, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'FAQsForm') {
        const res = yield call(api.faqs.getOne, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.getFailed(id, extractMessage(res.data)));
        }
      }

      if (id === 'BrowseContacts') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.contacts.getAll, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'ViewContact') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.contacts.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseActivities') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.activities.getAll, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'ViewActivities') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.dynamicComponent.getOne, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.getFailed(id, extractMessage(res.data)));
          }
        }
      }
    } catch (errors) {
      yield put(EntityActions.getFailed(id, "Connetion Error"));
    }
  },
  *post({ id, data, name }) {
    try {
      if (id === "ImageCrop") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.add, data.body, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "upload") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.changeMediaState, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }

      if (id === "SectionForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.sections.add, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "AnswerForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.answers.add, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "SeriesForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.series.add, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }

      if (id === "ApplicationForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.applications.add, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "UserForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.users.add, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, { ...res.data.content }));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseProducts") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.bulk.action, data.body, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, { content: { ...res.data.content }, isSwitch: data.isSwitch }));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }

      if (id === "BrowseUsers") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.bulk.action, data.body, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, { content: { ...res.data.content }, isSwitch: data.isSwitch }));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseAdmins") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.bulk.action, data.body, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, { content: { ...res.data.content }, isSwitch: data.isSwitch }));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "AdminForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.admins.add, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, { ...res.data.content }));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrowseWebsiteInfo") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.settings.add, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, { ...res.data.content }));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }

      if (id === 'ProductForm') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.products.post, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.postFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'PackageForm') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.packages.add, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.postFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'UnitSizeForm') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.unitSizes.add, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.postFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "RoleForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.roles.add, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "SliderForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.sliders.add, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "TeamForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.teams.add, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "NotificationForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.notifications.add, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BundleForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.bundles.add, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "DynamicFill") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.dynamicComponent.post, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "RequestAction") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.requests.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "BrandForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.brands.add, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "CountryForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.countries.add, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "JobForm") {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.jobs.add, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data.content));
          } else {
            yield put(EntityActions.postFailed(id, extractMessage(res.data)));
          }
        }
      }

      if (id === 'SubCategoriesForm' || id === 'CategoriesForm') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.subCategories.add, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, extractMessage(res.data)));
        }
      }

      if (id === "CustomersForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.customers.add, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "SpecificationsForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.specifications.add, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "AddressForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.addresses.add, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "UploadExcelFile") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.compatibleProducts.uploadExcelFile, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, extractMessage(res.data)));
        }
      }

      if (id === "FAQsForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.faqs.add, { body: data }, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, extractMessage(res.data)));
        }
      }

      if (id === "login") {
        const res = yield call(api.account.login, data);
        if (res.ok) {
          const { token } = res.data;
          yield put(AccountActions.setToken(token));
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, extractMessage(res.data)));
        }
      }

    } catch (errors) {
      yield put(EntityActions.postFailed(id, "Connection Error"));
    }
  },
  *put({ id, data }) {

  },
  *patch({ id, data }) {

    try {
      const token = getStorage(StorageKeys.token);

      if (id === 'BundleForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.bundles.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.patchSucceeded(id, res.data));
          } else {
            yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'AnswerForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.answers.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.patchSucceeded(id, res.data));
          } else {
            yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'UserForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.users.updateUser, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.patchSucceeded(id, res.data));
          } else {
            yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'AddressForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.addresses.update, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.patchSucceeded(id, res.data));
          } else {
            yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'AdminForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.admins.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.patchSucceeded(id, res.data));
          } else {
            yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'SeriesForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.series.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.patchSucceeded(id, res.data));
          } else {
            yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'RoleForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.roles.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'ProductForm') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.products.update, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data.content));
        } else {
          yield put(EntityActions.putFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'SectionForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.sections.updateSection, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'ApplicationForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.applications.update, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.patchSucceeded(id, res.data));
          } else {
            yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'SliderForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.sliders.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'PackageForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.packages.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'UnitSizeForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.unitSizes.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'TeamForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.teams.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseReviews') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.products.disable, data, { headers: { Authorization: token } });
          ;
          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'ShowTender') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.tenders.changeStatusTender, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'TenderOrderAction') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.tenders.changeStatusTenderOrder, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrandForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.brands.updateBrand, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'CountryForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.countries.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'JobForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.jobs.update, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.putSucceeded(id, res.data));
          } else {
            yield put(EntityActions.putFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === "CategoriesForm" || id === 'SubCategoriesForm') {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.categories.put, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "CustomersForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.customers.update, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "InlineInput") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.GenericHandlers.put, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "BrowseWebsiteInfo") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.settings.update, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "SpecificationsForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.specifications.update, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }
      if (id === "FAQsForm") {
        const token = getStorage(StorageKeys.token);
        const res = yield call(api.faqs.put, { id: data.id, editedData: data.values }, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.patchSucceeded(id, res.data));
        } else {
          yield put(EntityActions.patchFailed(id, extractMessage(res.data)));
        }
      }

    } catch (errors) {
      yield put(EntityActions.patchFailed(id, "Connection Error"));
    }

  },
  *delete({ id, data }) {
    try {
      const token = getStorage(StorageKeys.token);
      if (id === 'BrowseQuestions') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.questions.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'ViewQuestion') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.answers.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseBundles') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.bundles.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseUsers') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.users.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'AdminForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'UserForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'SliderForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseAddresses') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.addresses.delete, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrandForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseWebsiteInfo') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.settings.delete, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowsePackages') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.packages.delete, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseUnitSizes') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.unitSizes.delete, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'CustomersForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'SectionForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'ApplicationForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'SpecificationsForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'SubCategoriesForm' || id === 'CategoriesForm') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.media.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseAdmins') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.admins.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseSections') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.sections.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseSeries') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.series.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseContacts') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.contacts.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, res.data));
          }
        }
      }
      if (id === 'BrowseJobsApplications') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.jobsApplications.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseApplications') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.applications.delete, data, { headers: { Authorization: token } });

          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseSliders') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.sliders.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseTeams') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.teams.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseNotifications') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.notifications.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseQuestions') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.questions.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseRoles') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.roles.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseProducts') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.products.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseBrands') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.brands.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseCountries') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.countries.delete, data, { headers: { Authorization: token } });
          ;
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseJobs') {
        const token = getStorage(StorageKeys.token);
        if (token) {
          const res = yield call(api.jobs.delete, data, { headers: { Authorization: token } });
          if (res.ok) {
            yield put(EntityActions.deleteSucceeded(id, res.data));
          } else {
            yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
          }
        }
      }
      if (id === 'BrowseCategories' || id === 'BrowseSubCategories') {
        const res = yield call(api.categories.delete, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, res.data.content));
        } else {

          yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseCustomers') {
        const res = yield call(api.customers.delete, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, res.data.content));
        } else {

          yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'ProductForm') {
        const res = yield call(api.media.changeMediaState, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, res.data.content));
        } else {

          yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseSpecifications') {
        const res = yield call(api.specifications.delete, data, { headers: { Authorization: token } });

        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, res.data.content));
        } else {

          yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
        }
      }
      if (id === 'BrowseFAQs') {
        const res = yield call(api.faqs.delete, data, { headers: { Authorization: token } });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, res.data));
        } else {

          yield put(EntityActions.deleteFailed(id, extractMessage(res.data)));
        }
      }
    }
    catch (ex) {
      yield put(EntityActions.deleteFailed(id, "Connection Error"));
    }
  }
}
