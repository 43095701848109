
import { lazy } from 'react';

export const privateRoutes = [
  { permission: 'public', path: '/dashboard', component: lazy(() => import('../Screens/Dashboard')), exact: true },

  { permission: 'manage category', path: '/store-front/categories', component: lazy(() => import('../Screens/Categories/Browse')), exact: true },
  { permission: 'manage category', path: '/store-front/categories/add', component: lazy(() => import('../Screens/Categories/Add')), exact: true },
  { permission: 'manage category', path: '/store-front/categories/:id', component: lazy(() => import('../Screens/Categories/Edit-View/Controller')), exact: true },

  { permission: 'manage unit', path: '/store-front/units', component: lazy(() => import('../Screens/Packages/Browse')), exact: true },
  { permission: 'manage unit', path: '/store-front/units/add', component: lazy(() => import('../Screens/Packages/Add')), exact: true },
  { permission: 'manage unit', path: '/store-front/units/:id', component: lazy(() => import('../Screens/Packages/Edit-View/Controller')), exact: true },

  { permission: 'manage unit', path: '/store-front/unit-sizes', component: lazy(() => import('../Screens/UnitSizes')), exact: true },
  { permission: 'manage unit', path: '/store-front/unit-sizes/add', component: lazy(() => import('../Screens/UnitSizes/Add')), exact: true },
  { permission: 'manage unit', path: '/store-front/unit-sizes/:id', component: lazy(() => import('../Screens/UnitSizes/Edit-View/Controller')), exact: true },
  
  { permission: 'manage order', path: '/shop/manage-orders', component: lazy(() => import('../Screens/ManageOrders/Browse')), exact: true },

  { permission: 'manage user', path: '/administration/users', component: lazy(() => import('../Screens/Users/Browse')), exact: true },
  { permission: 'manage user', path: '/administration/users/add', component: lazy(() => import('../Screens/Users/Add')), exact: true },
  { permission: 'manage user', path: '/administration/users/:id', component: lazy(() => import('../Screens/Users/Edit-View')), exact: true },

  { permission: 'manage role', path: '/administration/roles', component: lazy(() => import('../Screens/Roles/Browse')), exact: true },
  { permission: 'manage role', path: '/administration/roles/add', component: lazy(() => import('../Screens/Roles/Add')), exact: true },
  { permission: 'manage role', path: '/administration/roles/:id', component: lazy(() => import('../Screens/Roles/Edit-View')), exact: true },

  { permission: 'manage admin', path: '/administration/admins', component: lazy(() => import('../Screens/Admins/Browse')), exact: true },
  { permission: 'manage admin', path: '/administration/admins/add', component: lazy(() => import('../Screens/Admins/Add')), exact: true },
  { permission: 'manage admin', path: '/administration/admins/:id', component: lazy(() => import('../Screens/Admins/Edit-View')), exact: true },

  { permission: 'manage product', path: '/store-front/products', component: lazy(() => import('../Screens/Products/Browse')), exact: true },
  { permission: 'manage product', path: '/store-front/products/add', component: lazy(() => import('../Screens/Products/Add')), exact: true },
  { permission: 'manage product', path: '/store-front/products/:id', component: lazy(() => import('../Screens/Products/Edit-View/Controller')), exact: true },
  { permission: 'manage product', path: '/store-front/products/:id/edit', component: lazy(() => import('../Screens/Products/Partials/ProductForm')), exact: true },

  { permission: 'manage section', path: '/store-front/sections', component: lazy(() => import('../Screens/Sections/Browse')), exact: true },
  { permission: 'manage section', path: '/store-front/sections/add', component: lazy(() => import('../Screens/Sections/Add')), exact: true },
  { permission: 'manage section', path: '/store-front/sections/:id', component: lazy(() => import('../Screens/Sections/Edit-View')), exact: true },

  { permission: 'manage slider', path: '/general/sliders', component: lazy(() => import('../Screens/Sliders/Browse')), exact: true },
  { permission: 'manage slider', path: '/general/sliders/add', component: lazy(() => import('../Screens/Sliders/Add')), exact: true },
  { permission: 'manage slider', path: '/general/sliders/:id', component: lazy(() => import('../Screens/Sliders/Edit-View')), exact: true },

  { permission: 'manage team', path: '/general/teams', component: lazy(() => import('../Screens/Teams/Browse')), exact: true },
  { permission: 'manage team', path: '/general/teams/add', component: lazy(() => import('../Screens/Teams/Add')), exact: true },
  { permission: 'manage team', path: '/general/teams/:id', component: lazy(() => import('../Screens/Teams/Edit-View')), exact: true },


  { permission: 'manage notification', path: '/general/notifications', component: lazy(() => import('../Screens/Notifications/Browse')), exact: true },
  { permission: 'manage notification', path: '/general/notifications/add', component: lazy(() => import('../Screens/Notifications/Add')), exact: true },
  { permission: 'manage notification', path: '/general/notifications/:id', component: lazy(() => import('../Screens/Notifications/Edit-View')), exact: true },

  { permission: 'manage question', path: '/general/questions', component: lazy(() => import('../Screens/Questions/Browse')), exact: true },
  { permission: 'manage question', path: '/general/questions/:id', component: lazy(() => import('../Screens/Questions/Edit-View')), exact: true },


  { permission: 'manage brand', path: '/store-front/brands', component: lazy(() => import('../Screens/Brands/Browse')), exact: true },
  { permission: 'manage brand', path: '/store-front/brands/add', component: lazy(() => import('../Screens/Brands/Add')), exact: true },
  { permission: 'manage brand', path: '/store-front/brands/:id', component: lazy(() => import('../Screens/Brands/Edit-View')), exact: true },

  { permission: 'manage country', path: '/general/countries', component: lazy(() => import('../Screens/Countries/Browse')), exact: true },
  { permission: 'manage country', path: '/general/countries/add', component: lazy(() => import('../Screens/Countries/Add')), exact: true },
  { permission: 'manage country', path: '/general/countries/:id', component: lazy(() => import('../Screens/Countries/Edit-View/Controller')), exact: true },

  { permission: 'manage application', path: '/store-front/applications', component: lazy(() => import('../Screens/Applications/Browse')), exact: true },
  { permission: 'manage application', path: '/store-front/applications/add', component: lazy(() => import('../Screens/Applications/Add')), exact: true },
  { permission: 'manage application', path: '/store-front/applications/:id', component: lazy(() => import('../Screens/Applications/Edit-View/Controller')), exact: true },

  { permission: 'manage job', path: '/recruitment/jobs', component: lazy(() => import('../Screens/Jobs/Browse')), exact: true },
  { permission: 'manage job', path: '/recruitment/jobs/add', component: lazy(() => import('../Screens/Jobs/Add')), exact: true },
  { permission: 'manage job', path: '/recruitment/jobs/:id', component: lazy(() => import('../Screens/Jobs/Edit-View/Controller')), exact: true },

  { permission: 'manage sub-category', path: '/store-front/sub-categories', component: lazy(() => import('../Screens/SubCategories/Browse')), exact: true },
  { permission: 'manage sub-category', path: '/store-front/sub-categories/add', component: lazy(() => import('../Screens/SubCategories/Add')), exact: true },
  { permission: 'manage sub-category', path: '/store-front/sub-categories/:id', component: lazy(() => import('../Screens/SubCategories/Edit-View')), exact: true },

  { permission: 'manage contact', path: '/general/contacts', component: lazy(() => import('../Screens/ManageContacts/Browse')), exact: true },
  { permission: 'manage contact', path: '/general/contacts/:id', component: lazy(() => import('../Screens/ManageContacts/Edit-View')), exact: true },

  { permission: 'manage admin activity', path: '/administration/activities', component: lazy(() => import('../Screens/AdminActivities/Browse')), exact: true },
  { permission: 'manage admin activity', path: '/administration/activities/:id', component: lazy(() => import('../Screens/AdminActivities/Edit-View')), exact: true },

  { permission: 'manage specification', path: '/store-front/specifications', component: lazy(() => import('../Screens/Specifications/Browse')), exact: true },
  { permission: 'manage specification', path: '/store-front/specifications/add', component: lazy(() => import('../Screens/Specifications/Add')), exact: true },
  { permission: 'manage specification', path: '/store-front/specifications/:id', component: lazy(() => import('../Screens/Specifications/Edit-View')), exact: true },

  { permission: 'manage series', path: '/store-front/series', component: lazy(() => import('../Screens/Series/Browse')), exact: true },
  { permission: 'manage series', path: '/store-front/series/add', component: lazy(() => import('../Screens/Series/Add')), exact: true },
  { permission: 'manage series', path: '/store-front/series/:id', component: lazy(() => import('../Screens/Series/Edit-View')), exact: true },

  { permission: 'manage customer', path: '/general/customers', component: lazy(() => import('../Screens/Customers/Browse')), exact: true },
  { permission: 'manage customer', path: '/general/customers/add', component: lazy(() => import('../Screens/Customers/Add')), exact: true },
  { permission: 'manage customer', path: '/general/customers/:id', component: lazy(() => import('../Screens/Customers/Edit-View/Controller')), exact: true },

  { permission: 'manage faq', path: '/general/faqs', component: lazy(() => import('../Screens/FAQs/Browse')), exact: true },
  { permission: 'manage faq', path: '/general/faqs/add', component: lazy(() => import('../Screens/FAQs/Add')), exact: true },
  { permission: 'manage faq', path: '/general/faqs/:id', component: lazy(() => import('../Screens/FAQs/Edit-View/Controller')), exact: true },

  { permission: 'manage job-application', path: '/recruitment/job-applications', component: lazy(() => import('../Screens/ManageJobsApplications/Browse')), exact: true },
  { permission: 'manage job-application', path: '/recruitment/job-applications/:id', component: lazy(() => import('../Screens/ManageJobsApplications/Edit-View')), exact: true },

  { permission: 'manage setting', path: '/general/website-info', component: lazy(() => import('../Screens/WebsiteInfo/Browse')), exact: true },
  { permission: 'manage request', path: '/administration/requests', component: lazy(() => import('../Screens/Requests/Browse')), exact: true },
  { permission: 'manage request', path: '/administration/requests/:id', component: lazy(() => import('../Screens/Requests/Edit-View')), exact: true },

  { permission: 'manage tender', path: '/shop/available-tenders', component: lazy(() => import('../Screens/Tenders/AvailableTenders/Browse')), exact: true },

  { permission: 'manage inquiries', path: '/shop/available-inquiries', component: lazy(() => import('../Screens/Inquiries/Browse')), exact: true },


  { permission: '', path: '/*', component: lazy(() => import('~/Components/UI/EmptyPage')), exact: true },

];



export const authRoutes = [
  { path: '/login', component: lazy(() => import('../Screens/Auth/Login')) },
];

export const publicRoutes = [
];


