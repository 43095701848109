export const types = [
  { name: "Supplier", id: 1 },
  { name: "Distributor", id: 2 },
  { name: "Normal User", id: 3 },
  { name: "Member", id: 4 },
];
export const typesAdd = [
  // { name: "Supplier", id: 1 },
  { name: "Distributor", id: 2 },
  { name: "Normal User", id: 3 },
];
export const staticRuleTypesOptions = [
  { name: "Fixed Amount", id: 1 },
  { name: "Percentage From Price", id: 2 },
  { name: "Percentage On Extra Price", id: 3 },
];
export const staticStatusTenderTypesOptions = [
  { name: "pending", id: 1 },
  { name: "approved", id: 2 },
  { name: "decline", id: 3 },
];
export const staticRuleTypes = {
  "Fixed Amount": 1,
  "Percentage From Price": 2,
  "Percentage On Extra Price": 3,
};

export const notificationTypes = [
  { name: "All Users and Admins", id: 1 },
  { name: "Specific Users", id: 2 },
  { name: "Specific Admins", id: 3 },
  { name: "All Suppliers", id: 4 },
  { name: "All Distributors", id: 5 },
  { name: "All Vendors (Distributors and Suppliers)", id: 6 },
  { name: "All Users", id: 7 },
  { name: "All Admins", id: 8 },
  { name: "Specific Vendors", id: 9 },
];
export const RequestModelTypes = [
  { name: "Review", id: "App\\Models\\Review" },
  { name: "Catalog", id: "App\\Models\\Catalog" },
  { name: "Product", id: "App\\Models\\Product" },
  { name: "User", id: "App\\Models\\User" },
];
