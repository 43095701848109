import { DataToQueryParams } from "~/Services/Helpers";
const normalFunction = (data) => data;
export const insertQueryParams = (values, customFunction = normalFunction) => {
  let extractedQuery = "";
  if (Object.keys(values).length === 1)
    extractedQuery =
      "?" +
      `${Object.keys(values)[0]}=${{
        ...base64Encode(values[Object.keys(values)[0]]),
      }}`;
  else
    extractedQuery = DataToQueryParams({
      ...base64Encode(customFunction(values)),
    });
  window.history.replaceState(null, "New State", extractedQuery);
};

const base64Encode = (data) => {
  const keys = Object.keys(data);
  let object = {};
  keys.map((attribute) => {
    if (data[attribute]?.id)
      object[attribute] = btoa(unescape(encodeURIComponent(data[attribute])));
    else
      object[attribute] = btoa(unescape(encodeURIComponent(data[attribute])));
  });
  return object;
};
