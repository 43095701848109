

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    root: {
        "& .MuiDrawer-paper": {
            width: '400px',
            padding: '0',
        },
        height: '100vh',
        overflow: 'hidden',
    },
    dropdownHeader: {
        display: 'flex',
        padding: '1rem',
        fontSize: '1rem',
        fontWeight: '600',
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        justifyContent: 'space-between',
        alignItems: "center",
        '& h2': {
            textTransform: 'uppercase',
            background: 'linear-gradient(to right, #c9fb36 0%, #0faba6 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontSize: '20px',
        }
    },
    content: {
        overflow: 'auto',
    },
    iconButton: {
        minWidth: 'calc(100px * 0.5)',
        height: 'calc(100px * 0.5)',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        padding: '5px',
        margin: '7px',
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        transition: "filter 300ms",
        '&:hover': {
            filter: 'brightness(1.2)',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '2rem',
            color: '#c9fb36',
        }
    },
    iconRight: {
        marginRight: 'auto',
        marginTop: '3px',
        fontSize: '10px',
        color: theme.palette.type === "light" ? 'blue' : '#9f9fef',
    },
    menuItem: {
        justifyContent: 'space-between',
        textAlign: 'left',
        height: '82px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '3px',
        transition: 'background 500ms',
        cursor: 'default',
        padding: '10px 0.5rem',
        '& .icon-button ': {
            marginRight: "0.5rem",
        },
        '& .icon-button:hover': {
            filter: 'none',
        },
        '&:hover': {
            backgroundColor: '#dfdfdf70',
        },
        '&::after': {
            content: "''",
            width: '8px',
            height: '8px',
            MozBorderRadius: '7.5px',
            WebkitBorderRadius: "7.5px",
            borderRadius: '7.5px',
            backgroundColor: 'var(--my-color-var)',
        }

    },
    footer: {
        padding: '10px',
        "& a": {
            fontWeight: 500,
            color: 'blue',
        }
    },
    messageContent: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: '0.8rem',
        fontWeight: '500',
        lineHeight: '1.4',
        color: theme.palette.type === "light" ? '#212529ad' : "rgba(255, 255, 255, 0.7)",
        maxWidth: '92%',
        padding: '0.4rem',
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '100%',
        "& .MuiCircularProgress-root": {
            color: theme.palette.type === "light" ? theme.palette.primary.main : theme.palette.secondary.main,
        }
    },
    title: {
        fontWeight: '600',
        textTransform: 'capitalize',
        color: theme.palette.type === "light" ? theme.palette.primary.main : 'rgba(255, 255, 255, 0.7)'
    },
}));


export default useStyles;