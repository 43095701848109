import { isEmpty } from "~/Services/Helpers/isEmpty";

const Seconds = (n) => n * 1000;

const baseURL = !isEmpty(process.env.PUBLIC_URL)
  ? process.env.PUBLIC_URL
  : "https://stg.laboshop.ae";

export const getURL = (path) =>
  !isEmpty(process.env.REACT_APP_BACKEND_BASE_URL)
    ? `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}/`
    : `https://stg.laboshop.ae/${path}/`;

export default {
  baseURL,
  apiUrl: getURL("api"),
  timeout: Seconds(100),
  clientToken: "SECRET_TOKEN",
  version: "1.0.0",

  email: "support@membs.com",
  phone: "+963932415979",
  storageUrl:  getURL("storage"),
  multipleMediaFileUpload: "admin/file-upload",
  multipleMediaImageUpload: "admin/media-upload",
};
