import { useAppContext } from '~/Services/Providers';
const openAside = 275;
const closedAside = 110;

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    //this flex is breaking the table
    // display: 'flex',
    direction: theme.direction,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.type === "light" ? 'white' : '#424242',
    paddingLeft: closedAside + 35,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    },
    minHeight: '93.8vh',

  },
  drawerOpenContent: {
    paddingLeft: openAside + 35,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }
});


export default styles;